.burger {
  width: 32px;
  height: 36px;
  cursor: pointer;
  right: 2rem;
  top: 2rem;
  z-index: 20;
  padding-top: 10px;
}

.burger span {
  width: 100%;
  height: 0px;
  background-color: #397f87;
  display: block;
  transition: background-color 0.5s ease-in-out;
}

.burger span::before,
.burger span::after {
  content: "";
  width: 80%;
  margin: 0 auto;
  background-color: #397f87;
  display: block;
  transition: all 0.2s ease-in-out;
  height: 2px;
}

.burger span::before {
  transform: translateY(-5px);
}

.burger span::after {
  transform: translateY(5px);
  margin-top: -2px;
}

.burger.active span {
  background-color: transparent;
}

.burger.active span::before {
  transform: rotateZ(45deg) translateY(0);
}

.burger.active span::after {
  transform: rotateZ(-45deg) translateY(0);
}
